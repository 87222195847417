<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <top-title
        type="04"
        header="뉴스 & 공지사항"
        body="BMS 제품 관련 보도자료 및 공지사항 콘텐츠를 제공합니다."
    />
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb"><a href="#">BMS 소식</a></li>
            <li class="breadcrumb">뉴스 & 공지사항</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->
      <card-and-list
          :hasHeader="false"
          @getMoreList="getMoreList"
      />
    </div>
  </main>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import TopTitle from "@/components/common/TopTitle";
import CardAndList from "@/components/common/CardAndList";
import {mapActions} from "vuex";

export default {
  name: 'NewsList',
  components: {
    CardAndList,
    // eslint-disable-next-line vue/no-unused-components
    InfiniteLoading,
    TopTitle,
  },
  async mounted() {
    await this.initBoardList({
      type: 'news-and-notice',
    })
  },
  methods: {
    ...mapActions(["fetchBoardList", "initBoardList"]),
    async getMoreList() {
      await this.fetchBoardList({
        query: {
          page: this.$store.state.board.page + 1,
        },
        type: 'news-and-notice',
      })
    },
  },
};
</script>

<style scoped>

</style>
